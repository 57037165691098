/**
 * Handles keypress events to allow only numeric digits, plus (+), and minus (-) characters.
 */
export const handleKeys = (event) => {
  try {
    // Check if the charCode is a numeric digit (48-57), plus (+), or minus (-)
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      event.charCode === 43 || // Plus (+)
      event.charCode === 45 // Minus (-)
    ) {
      return true; // Allow the input
    } else {
      event.preventDefault(); // Prevent input for other characters
    }
  } catch (error) {
    console.error("Error in handleKeys:", error);
  }
};

/**
 * Formats a given number as a US phone number.
 */
export const formatNumberInUs = (number) => {
  try {
    if (number) {
      // Remove all non-digit characters
      const cleanedNumber = number.replace(/\D/g, "");

      // Check if the cleanedNumber matches the expected format
      if (/^\+?1(\d{3})(\d{3})(\d{4})$/.test(cleanedNumber)) {
        // Format the number as +1 (XXX) XXX-XXXX
        return cleanedNumber.replace(
          /^(\+?1)(\d{3})(\d{3})(\d{4})$/,
          "+$1 ($2) $3-$4"
        );
      }

      // Return the original input if it doesn't match the expected format
      return number;
    }
  } catch (error) {
    console.error("Error in formatNumberInUs:", error);
  }
};

/**
 * Determine whether the given `input` is iterable.
 */
export const  isIterable = (input) => {  
  if (input === null || input === undefined) {
    return false
  }

  return typeof input[Symbol.iterator] === 'function'
}


export const mobileAndTabletCheck = () => {
  let check = false;
  (function(a) {
    // Detect mobile or tablet using user-agent
    if (/android|bb\d+|meego.+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|iphone|ipod|ipad|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|playbook|silk|symbian|treo|vodafone|wap|windows ce|xda|xiino|android|tablet/i.test(a)) {
      check = true;
    }
    // Check for iPad specifically in Chrome (using navigator.maxTouchPoints)
    if (navigator.userAgent.includes("Macintosh") && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};